<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <!-- マスタ初期登録(モジュール選択)-->
      <NavBar tittle="label.lbl_masterInit" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <v-card style="width: 100%; height: 150px; margin-left: 12px; margin-right: 12px">
              <v-card-text>
                <p
                  style="
                    font-size: x-large;
                    font-weight: bold;
                    text-align: left;
                    white-space: nowrap;
                  "
                >
                  マスタ登録状況
                </p>
                <p style="font-size: large; text-align: left; white-space: pre-line">
                  {{ explanation }}
                </p>
              </v-card-text>
            </v-card>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- テンプレート一括ダウンロードボタン -->
                <a @click="downloadMstTempleteFile()">
                  <v-btn class="get-btn" style="float: left; width: 13rem">{{
                    $t("btn.btn_templateDownload")
                  }}</v-btn>
                </a>
              </div>
              <div class="btn-search-area" style="float: right">
                <!--戻るボタン-->
                <v-btn class="other-btn" style="float: right" @click="returnModuleSelect()">{{
                  $t("btn.btn_back")
                }}</v-btn>
                <!--完了ボタン-->
                <v-btn
                  class="other-btn"
                  style="float: right"
                  @click="initCompleteMsg()"
                  :disabled="initCompleteDisabledFlg"
                  >{{ $t("btn.btn_complete") }}</v-btn
                >
                <!--一括削除ボタン-->
                <v-btn class="other-btn" style="float: right" @click="batchDeleteChk()">{{
                  $t("btn.btn_batchDelete")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <!-- マスタ情報 -->
        <v-data-table
          id="listData"
          :class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
        >
          <!-- 取込 -->
          <template v-slot:[`item.importfile`]="{ item }">
            <div style="margin-top: 6px">
              <v-file-input
                outlined
                dense
                v-model="item.importfile"
                @change="fileChange"
                :error-messages="item.importFileCheckMsg"
              >
              </v-file-input>
            </div>
          </template>
          <!-- 登録 -->
          <template v-slot:[`item.regist`]="{ item, index }">
            <div style="align-items: center">
              <v-btn small @click="fileRegist(item, index)" text>
                <v-icon>mdi-database-import</v-icon>
              </v-btn>
            </div>
          </template>
          <!-- 削除選択チェックボックス -->
          <template v-slot:[`item.deleteCheck`]="{ item }">
            <div class="" v-show="item.modifyFlg">
              <input
                type="checkbox"
                v-model="item.deleteCheck"
                :value="item.listCheckbox"
                style="transform: scale(2)"
              />
            </div>
          </template>

          <!-- 削除選択チェックボックス -->
          <template v-slot:[`item.registMaster`]="{ item }">
            <div>
              {{ item.registMaster }}<span style="color: red">{{ item.arbitraryFlg }}</span>
            </div>
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :iniClientSelectFlg="infoDialog.iniClientSelectFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
//import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.MENU_ID.BIZ_DELIVERY_INSTRUCTIONS_SEARCH,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    explanation: "", // 説明

    loadingCounter: 0, // ローディング画面表示フラグ
    officeCd: "", // 営業所コード
    clientCd: "", // 取引先コード

    openMenu: null, // メニュー

    inputList: [], // マスタ一覧
    deleteList: [], //削除リスト

    initCompleteDisabledFlg: false,
    masterCountUpdateFlg: false,

    importfile: {},
    importFileCheckMsg: "",

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ

    tableData: "tableData",

    // ヘッダ
    headerItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "no",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // モジュール
      {
        text: i18n.tc("label.lbl_module"),
        value: "module",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 登録必要マスタ
      {
        text: i18n.tc("label.lbl_registMaster"),
        value: "registMaster",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 登録済件数
      {
        text: i18n.tc("label.lbl_registeredMasterCount"),
        value: "regCount",
        width: "5%",
        align: "right",
        sortable: false,
      },
      // 余白
      {
        width: "3%",
        align: "center",
        sortable: false,
      },
      // アップロード
      {
        text: i18n.tc("label.lbl_upload"),
        value: "importfile",
        width: "20%",
        align: "center",
        sortable: false,
      },
      // 登録
      {
        text: i18n.tc("label.lbl_regist"),
        value: "regist",
        width: "7%",
        align: "center",
        sortable: false,
      },
      //削除選択
      {
        text: i18n.tc("label.lbl_delete") + i18n.tc("label.lbl_select"),
        value: "deleteCheck",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  created() {
    // 前画面からのパラメータ設定
    this.clientCd = this.$route.params.clientCd;
    this.masterCountUpdateFlg = this.$route.params.masterCountUpdateFlg;
  },

  methods: {
    onBlur() {
      //
    },

    /**
     * 初期値
     */
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.officeCd = sessionStorage.getItem("office_cd");
      if (this.masterCountUpdateFlg) {
        // マスタ登録件数更新
        this.updateMasterCount();
      } else {
        // 初期データ取得
        this.getMasterList();
      }

      this.explanation =
        "選択したモジュールで必要な初期マスタは次の通りです。\r\n必要なマスタを上から順番に登録していきましょう。";
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * マスタ一覧取得
     */
    getMasterList() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // パラメータ設定
      config.params.officeCd = this.officeCd; // 営業所コード
      config.params.clientCd = this.clientCd; // 取引先コード

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_OFFICE_MASTER_JANAL, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            console.debug("getMasterList() Response", response); // 成功
            const list = [];
            var i = 1;
            if (jsonData.resCom.resComCode == "000") {
              // リストを取得
              jsonData.resIdv.officeMasterJanalList.forEach((row) => {
                var arbitrary = "";
                if (row.arbitraryFlg == "1") {
                  arbitrary = "*";
                }
                var modifyFlg = false;
                if (row.modifyFlg == "1") {
                  modifyFlg = true;
                }
                list.push({
                  no: i++, // No
                  module: row.moduleCd, // モジュール
                  registMaster: row.beLogicalName, // 登録必要マスタ
                  regCount: row.recordCount, // 登録済件数
                  masterGroup: row.masterGroup, // マスターグループ
                  completeFlg: row.completeFlg, // 完了フラグ
                  arbitraryFlg: arbitrary, // 必須任意フラグ
                  importfile: {}, // 取込ファイル
                  importFileCheckMsg: "",
                  modifyFlg: modifyFlg, // 更新種別フラグ
                });

                if (row.completeFlg == "0") {
                  this.initCompleteDisabledFlg = true;
                }
              });

              this.inputList = list;
              if (this.inputList.length == 0) {
                this.initCompleteDisabledFlg = true;
              }

              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     *  マスタ登録件数更新処理
     */
    updateMasterCount() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_003; // 画面ID
      // パラメータ設定
      body.reqIdv.officeCd = this.officeCd; //営業所コード
      body.reqIdv.clientCd = this.clientCd; //取引先コード

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.INI_OFFICE_MASTER_RECORD_COUNT_UPDATE,
            body,
            appConfig.APP_CONFIG
          )

          .then((response) => {
            console.debug("updateMasterCount() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 処理なし
              this.getMasterList();
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
      });
    },

    /**
     *  登録押下時処理
     */
    fileRegist(item, index) {
      this.messageClear();
      if (item.importfile == null || item.importfile.name == null) {
        this.inputList[index].importFileCheckMsg = i18n.tc("check.chk_select");
        return;
      }
      this.$router.push({
        name: appConfig.MENU_ID.P_MST_MASTER_FILE,
        params: {
          // 初期導入フラグ
          iniFlg: true,
          // 取り込みファイル
          importfile: item.importfile,
          // 取込種別
          importType: item.masterGroup,
          // 取引先コード
          clientCd: this.clientCd,
        },
      });
    },

    /**
     *  完了ボタン押下時処理
     */
    initCompleteMsg() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        "マスタ初期導入を完了",
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.initComplete;
    },

    /**
     *  初期導入完了処理
     */
    initComplete() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_003; // 画面ID
      // パラメータ設定
      body.reqIdv.officeCd = this.officeCd; //営業所コード
      body.reqIdv.clientCd = this.clientCd; //取引先コード

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.INI_OFFICE_MASTER_COMPLETED, body, appConfig.APP_CONFIG)

          .then((response) => {
            console.debug("initComplete() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                "マスタ初期導入を完了",
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.iniClientSelectFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
      });
    },

    /**
     *  アップロードファイル変更時処理
     */
    fileChange() {
      //エラーメッセージクリア
      this.messageClear();
    },

    /**
     *  エラーメッセージクリア処理
     */
    messageClear() {
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].importFileCheckMsg = "";
      }
    },

    /**
     *  一括削除ボタン押下時処理
     */
    batchDeleteChk() {
      this.deleteList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].deleteCheck == true) {
          this.deleteList.push(this.inputList[i].masterGroup);
        }
      }

      if (this.deleteList.length == 0) {
        this.errorFlg = false;
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-INI-003_001_E");
        return;
      }

      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_delete"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.batchDelete;
    },

    /**
     *  一括削除処理
     */
    batchDelete() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_003; // 画面ID
      // パラメータ設定
      body.reqIdv.officeCd = this.officeCd; //営業所コード
      body.reqIdv.clientCd = this.clientCd; //取引先コード
      body.reqIdv.deleteMasterGroupList = this.deleteList; //削除マスタグループリスト

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.INI_OFFICE_MASTER_DELETE, body, appConfig.APP_CONFIG)

          .then((response) => {
            console.debug("pushNext() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_delete"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              //再検索
              this.getMasterList();
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
      });
    },

    /**
     * マスタテンプレートダウンロード処理
     */
    downloadMstTempleteFile() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      console.debug(config);
      // パラメータ設定
      config.params.path = "Master_Template";
      config.params.fileName = "Master_Template_v240401.zip";

      console.debug(config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_MASTER_TEMPLETE_FILE_DOWMLOAD_URL, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              const downloadUrl = jsonData.resIdv.url;
              location.href = downloadUrl;
              resolve();
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     *  戻るボタン押下処理
     */
    returnModuleSelect() {
      this.$router.push({
        name: appConfig.MENU_ID.M_INI_MODULE_SELECT,
        params: {
          // 取引先コード
          clientCd: this.clientCd,
        },
      });
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}
#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 500px;
  height: 100%;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  width: 100%;
  margin-right: 12px;
}
.textbox {
  width: 11.2rem;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.span1 {
  width: 4.3rem;
}
.span2 {
  width: 8rem;
}
a {
  text-decoration: underline;
}

.cargos_btn ::v-deep .v-btn__content {
  color: #000 !important;
  font-weight: normal !important;
}
.v-btn.v-btn--has-bg.btn_search {
  width: 5.5rem;
}

.date-style {
  width: 11rem;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

// 検索結果表示件数部分
.display-search-info-label {
  width: 100%;
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
  font-size: x-large;
  font-weight: bold;
}
</style>
